@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.jobPreferences {

  &Form {
    margin-top: 24px;

    &Group {
      display: flex;
      margin-bottom: 24px;

      & .MuiInputBase-root {

        font-size: 14px !important;

      }



      & .MuiOutlinedInput-root {
        font-size: 14px !important;


      }

      @include msd {
        flex-direction: column;
        margin-bottom: 24px;
      }
    }

    &Input {
      flex-grow: 1;
      font-size: 14px !important;

      @include msd {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.fieldError {
  display: flex;
}