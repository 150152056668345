@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.button {
  box-sizing: border-box;
  padding: 20px 25px;
  background: white;
  outline: 0;
  border-radius: 4px;
  color: $primaryBlue;
  border: 1px solid $primaryBlue;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  height: 40px;

  @include md {
    padding: 20px 25px;
  }

  &:disabled {
    background: #d5d9dc !important;
  }

  &Primary {
    background: $primaryBlue;
    color: white;
    border: 0;
  }

  &Secondary {
    color: $primaryBlue;
    background: white;
    border: 1px solid $primaryBlue;
  }

  &:hover {
    cursor: pointer;
    box-shadow: $box-shadow-1;
  }
}

.loader {
  display: flex;
  height: 50px;
  width: 33px;
}
