.main {
    width: calc(100vw);
    height: 100%;
    display: flex;
    flex-direction: column;



    .topbar {
        width: 100%;
        height: 59px;
        background: #f0f0f0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;

        label {
            font-family: 'Product Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.02em;
            color: #353535;
        }
    }

    .content {
        max-height: calc(100% - 59px - 50px);
        overflow-y: scroll;
        flex: 1;

        .industryContainer {
            background-color: pink;
        }
    }

    .buttonContent {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;

        .resetButton {
            width: 45%;
            border: 1px solid rgba(35, 121, 234, 0.5);
            border-radius: 4px;
            text-transform: capitalize;
            font-family: 'Product Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.0075em;
            height: 44px;

        }

        .saveButton {
            width: 45%;
            background: #136FD3;
            border-radius: 4px;
            text-transform: capitalize;
            color: #fff;
            height: 44px;
            font-family: 'Product Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.0075em;
        }
    }

}