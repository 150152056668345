@import 'styles/mixins';

.maintainNotification {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 14px 48px;
  margin: 0 auto;
  background-color: #fff9f0;

  @include sm {
    padding: 8px 48px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    user-select: none;
  }

  .icon {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }

  .text {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ff9000;
    text-align: left;
  }

  .refresh {
    padding: 0;
    margin: 0 0 0 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ff9000;
    text-decoration: underline;
    cursor: pointer;
  }
}