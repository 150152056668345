@import "styles/mixins.scss";
@import "styles/variable.scss";

$animationTime: 0.3s;

@keyframes modal {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes close {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes showForMain {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-30%);
    }
}

@keyframes hideForMain {
    0% {
        transform: translateX(-30%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes showForSub {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes hideForSub {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

.mobile {
    display: none;

    @include msd {
        background: #fff;
        height: 100vh;
        // height: fit-content;
        width: 100vw;
        // width: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        position: fixed;
        // top: 50px;
        bottom: 0px;
        left: 0px;
        z-index: 25000;
        padding-bottom: constant(safe-area-inset-bottom);
        /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom);

        /*兼容 IOS>11.2*/
        .buttonContainer {
            height: 76px;
            padding: 0 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: 0.5px solid #bcbcbc;
            margin-top: 10px;

            .resetBtn {
                color: #515151;
                display: flex;
                height: 44px;
                display: flex;
                align-items: center;
                background: #f6f6f6;
                justify-content: center;
                border-radius: 4px;
                font-size: 14px;
            }
        }

        &.showModal {
            animation: modal $animationTime ease forwards;
        }

        &.closingModal {
            animation: close $animationTime ease forwards;
        }

        .topContainer {
            position: relative;
            overflow: hidden;
            width: 100%;

            .mobileHeaderSearch {
                margin: 16px 20px 10px;
                border-radius: 4px;
                background: #f6f6f6;
                display: flex;
                align-items: center;

                :global {
                    .ant-input-affix-wrapper {
                        padding: 6px 11px !important;
                        border: none !important;
                        background: #f6f6f6 !important;
                        box-shadow: none !important;
                    }
                }

                .cancel {
                    color: #121212;
                    word-break: keep-all;
                    margin: 0 12px;
                }
            }

            .firstPage {
                width: 100%;
                z-index: 249;

                &.show {
                    animation: showForMain $animationTime ease forwards;
                }

                &.hide {
                    animation: hideForMain $animationTime ease forwards;
                }

                .columnMain {
                    width: 100%;
                    height: calc(100vh - 56px - 76px - 10px);
                    overflow: auto;
                    padding: 0px 14px 0;

                    .item {
                        width: 100%;
                    }
                }
            }

            .secondPage {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 250;
                background: #fff;
                transform: translateX(100%);

                &.show {
                    animation: showForSub $animationTime ease forwards;
                }

                &.hide {
                    animation: hideForSub $animationTime ease forwards;
                }

                .subContainer {
                    height: inherit;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-self: flex-start;

                    .secondContainer {
                        height: 100%;
                        width: 50%;
                        border-right: 0.5px solid #bcbcbc;
                        overflow-y: auto;

                        .item {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .secondContainer {
        padding: 0 12px;
    }
}

.headerContainer {
    width: 100%;
    height: 59px;
    background: #f0f0f0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .headerLeft {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 12px;
    }

    label {
        font-family: "Product Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        color: #353535;
        margin-left: 8px;
        // position: relative;
        // top:-15px;
        // left: 15px;
    }
}

.container {
    position: absolute;
    max-height: 400px;
    min-height: 200px;
    background: #fff;
    min-width: 250px;
    // top: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;
    z-index: 1000;
    border-radius: 4px;
    padding: 4px;
    overflow: hidden;

    @include msd {
        display: none;
    }

    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);

    .column {
        // display: flex;
        // flex-direction: column;
        // width: inherit;
        // overflow: auto;
        border-right: 1px solid #f0f0f0;
        overflow: scroll;
        min-height: calc(100% + 1px);
        padding-right: 4px;

        &:last-child {
            border: none;
        }
    }
}

.emptyBox {
    margin: 30px auto;
    width: 250px;
}

.item {
    min-height: 36px;
    // width: 100%;
    width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-left: 8px;
    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;

    &.active {
        background: #f5f7fb;
    }

    @include msd {
        width: 100%;
        min-height: 42px;
    }

    &:hover {
        background: #f5f7fb;

        @include msd {
            background: #fff;

            &.active {
                background: #f5f7fb;
            }
        }
    }

    .title {
        flex: 1;
    }

    .content {
        display: flex;
        align-items: center;
        padding: 8px 0;
    }

    .contentActive {
        color: #2378e5;
    }

    .iconBox {
        height: 14px;
        width: 14px;
        border-radius: 3px;
        margin-right: 8px;
        border: 1px solid #b8b8b8;
        flex-shrink: 0;
    }

    .iconBoxActive {
        height: 14px;
        width: 14px;
        margin-right: 8px;
        border-radius: 3px;
        border-color: #2378e5;
        background: #2378e5 url("./checked.svg") no-repeat center center;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .iconBoxActiveHalf {
        border-color: #2378e5;
        background: #2378e5 url("./half.svg") no-repeat center center;
    }

    .iconHot {
        color: #ff3b30;
        text-align: center;
        font-size: 12px;
        transform: scale(0.8);
        font-style: normal;
        font-weight: 500;
        padding: 3px 5px;
        border-radius: 2px;
        background: #ffd8d6;
        margin-left: 4px;
        white-space: nowrap;
    }

    // .iconBox {

    //     color: #2379EA;
    //     margin-right: 8px;
    // }

    .rightContainer {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        justify-content: flex-end;
        width: 30px;

        .arrow {
            width: 30px;
            display: flex;
            flex: 0;
            align-items: center;
        }
    }
}
