.exchangeIconDownWrapperOnRemoteJob {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  color: #515151;

  &[data-hover="true"] {
    color: #2378e5;
  }
}

.moneyDropdownWrapper {
  padding: 20px 12px !important;
  list-style-type: none !important;
  background-color: #ffffff !important;
  background-clip: padding-box !important;
  border-radius: 8px !important;
  outline: none !important;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  width: 300px;


  .header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 12px;
  }

  :global {
    .ant-dropdown-menu {
      padding: 0 !important;
      background-color: #ffffff !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      max-height: 60vh;
      overflow: hidden;
      overflow-y: auto;
    }

    .ant-dropdown-menu-item {
      padding: 0px !important;

      .ant-dropdown-menu-title-content {
        padding: 0px !important;
      }
    }
  }

  .exchangeMoneyListWrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    padding: 11px 8px;
    color: #121212;

    &[data-checked="true"] {
      color: #2378e5;
      background: var(--brand-color-01-f-5-f-7-fb, #f5f7fb);
    }
  }

  .listSalary {
    // color: #121212;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
  }
}

.moneyExchangeCard {
  :global {
    .ant-modal-content {
      padding: 40px 24px !important;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-close {
      display: none;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headerText {
      color: #000;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      height: 1.5;
    }

    .closeIconWrapper {
      display: flex;
      align-items: center;
      height: 24px;
      cursor: pointer;
    }
  }

  .convertFieldWrapper {
    display: flex;
    width: 452px;
    padding: 20px 20px 32px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: #f9f9f9;

    .convertFieldRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .money {
      flex: 1;
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 30px;
    }
  }

  .timeFooter {
    text-align: center;
    color: var(--neutral-color-text-03-b-8-b-8-b-8, var(--neutral-color-text-03-b-8-b-8-b-8, #b8b8b8));
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

.row {
  display: flex;
  align-items: center;
}

.exchangeNumberInput {
  flex: 1;
  // margin-left: 100px;
  border: 0;
  box-shadow: none !important;
  background-color: transparent;

  text-align: right;
  font-size: 24px;
  height: 30px;
  font-weight: 700;

  // :global {
  //   .ant-input-number-handler-wrap {
  //     display: none;
  //   }
  //   ant-input,
  //   .ant-input-number-input {
  //     text-align: right;
  //     font-size: 24px;
  //     height: 30px;
  //     font-weight: 700;
  //   }
  // }
}

.mobileExchangeDrawer {
  .mobileExchangeDrawerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black-800121212, var(--neutral-color-text-06121212, #121212));
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }

  :global {
    .ant-drawer-body {
      padding: 12px 16px;
    }

    .ant-drawer-header {
      border: none !important;
    }
  }

  .mobileExchangeDrawerListItem {
    display: flex;
    justify-content: space-between;
    padding: 18px 8px;
    border-bottom: 1px solid var(--neutral-color-line-01-f-6-f-6-f-6, #f6f6f6);

    &[data-checked="true"] {
      color: #2378e5;
      // background-color: ;
      border-bottom: 1px solid var(--neutral-color-line-01-f-6-f-6-f-6, #f6f6f6);
      background: var(--brand-color-01-f-5-f-7-fb, #f5f7fb);

      .mobileExchangeDrawerListItemSalary {
        color: currentColor;
      }
    }
  }

  .mobileExchangeDrawerListItemSalary {
    color: #121212;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
}