@import 'styles/mixins.scss';
@import 'styles/variable.scss';

$animationTime : 0.3s;

@keyframes modal {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes close {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes showForMain {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-30%);
    }
}

@keyframes hideForMain {
    0% {
        transform: translateX(-30%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes showForSub {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes hideForSub {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

.mask{
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    z-index:1;
}

.mobile {
    display: none;

    @include msd {
        background: #fff;
        height: 100vh;
        // height: fit-content;
        width: 100vw;
        // width: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 50px;
        left: 0px;
        z-index: 25000;
        padding-bottom: constant(safe-area-inset-bottom);
        /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom);

        /*兼容 IOS>11.2*/
        .buttonContainer {
            width: 100%;
            height: 76px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            border-top: 0.5px solid #BCBCBC;
            margin-top: 10px;
        }

        &.showModal {
            animation: modal $animationTime ease forwards;
        }

        &.closingModal {
            animation: close $animationTime ease forwards;

        }

        .topContainer {
            position: relative;
            overflow: hidden;
            width: 100%;

            .firstPage {
                width: 100%;
                z-index: 249;

                &.show {
                    animation: showForMain $animationTime ease forwards;
                }

                &.hide {
                    animation: hideForMain $animationTime ease forwards;
                }

                .columnMain {
                    width: 100%;
                    height: calc(100vh - 56px - 76px - 10px);
                    overflow: auto;
                    padding-top: 5px;

                    .item {
                        width: 100%;

                    }
                }
            }

            .secondPage {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 250;
                background: #fff;
                transform: translateX(100%);

                &.show {
                    animation: showForSub $animationTime ease forwards;
                }

                &.hide {
                    animation: hideForSub $animationTime ease forwards;
                }

                .subContainer {
                    height: inherit;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-self: flex-start;

                    .secondContainer {
                        height: 100%;
                        width: 100%;
                        // border-right: 0.5px solid #BCBCBC;
                        overflow-y: auto;

                        .item {
                            width: 100%;
                        }
                    }

                    .thirdContainer {
                        height: 100%;
                        width: 50%;
                        overflow-y: auto;

                        .item {
                            width: 100%;
                        }
                    }
                }
            }

        }
    }

}

.headerContainer {
    width: 100%;
    height: 59px;
    background: #F0F0F0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .headerLeft {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    :global {
        .MuiSvgIcon-root {
            width: 24px;
            height: 24px;
        }

    }

    label {
        font-family: 'Product Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        color: #353535;
        margin-left: 20px;
        // position: relative;
        // top:-15px;
        // left: 15px;
    }
}

.container {
    position: absolute;
    height: 501px;
    background: #fff;
    top: 55px;
    display: flex;
    z-index: 100;
    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;

    @include msd {
        display: none;
    }

    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    // filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1)) drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.1));

    .column {
        // display: flex;
        // flex-direction: column;
        // width: inherit;
        // overflow: auto;
        border-right: 1px solid #F0F0F0;
        overflow: scroll;
        min-height: calc(100% + 1px);

        :nth-child(3) {
            border: none;
        }
    }
}

.item {
    height: 50px;
    width: 277px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 20px;

    &.active {
        background: #F0F0F0;
    }

    &:hover {
        background: #F0F0F0;

        @include msd {
            background: #fff;

            &.active {
                background: #F0F0F0;
            }
        }
    }

    .title {
        flex: 1;
    }

    .rightContainer {
        display: flex;
        flex-direction: row;
        margin-right: 10px;

        &.arrow {
            height: 100%;
            width: 40px;
            display: flex;
            flex: 0;
        }

        .icon {
            // position: absolute;
            // transform: rotateZ(90deg);
            // right: 40px;
            height: 24px;
            width: 24px;
            color: #2379EA;
        }
    }

}
.initSalary{
    :global{
        .MuiOutlinedInput-root{
            font-size: 14px;
        }
    }
}