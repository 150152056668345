@import 'styles/mixins.scss';

.jobListOptionAlerts {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 17px;

  @include sm {
    display: none;
  }

  &Main {
    width: 100%;

    // border-radius: 8px;
    // border: 1px solid #e5e6eb;
    // background: #fff;
    border-radius: 8px;
    background: linear-gradient(90deg, #eaf4ff 0%, #f2f9ff 100%);

    display: flex;
    align-items: center;

    padding: 20px;
    justify-content: space-between;

    position: relative;
  }

  &Left {
    display: flex;
  }

  &Content {
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }

  &Setting {
    cursor: pointer;

    color: var(--Neutral-White, #fff);
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    height: 36px;
    padding: 10px 24px;
    gap: 8px;
    border-radius: 34px;
    background: var(--brand-color-052378-e-5, #2378e5);
  }

  &Title {
    color: #1d2129;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.135px;
  }

  &Question {
    color: #7d7d7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.105px;
  }

  &Jobs {
    color: #2378e5;
    word-break: break-all;
  }

  &Closed {
    position: absolute;
    top: 8px;
    right: 8px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.jobAlertsModal {
  &Action {
    margin-top: 20px;
  }

  &Switch {
    color: #4e5969;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.18px;
  }

  &Info {
    color: #4e5969;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.113px;
    width: 460px;
    word-break: break-all;

    margin-top: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
  }

  &InfoText {
    color: #579bf5;
  }

  &FormControl {
    margin-top: 12px;
    width: 460px;
    margin-bottom: 24px;
  }

  &FormControlTitle {
    margin-top: 20px;
    margin-bottom: 8px;

    color: #4e5969;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.113px;
  }

  &Divider {
    width: 460px;
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    margin-top: 24px;
  }

  &Cancel {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #c9cdd4;
    color: #1d2129;
    padding: 8px 40px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &Save {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #2378e5;
    color: #ffffff;
    padding: 8px 40px;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 24px !important;
  }

  &HelperText {
    margin-left: 0 !important;
    color: red !important;
  }
}

.hideAlertSetting {
  display: none;
  height: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.jobAlertsModalActions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
