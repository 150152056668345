.main {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 20px 24px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px;
}

.left {
  display: flex;
  gap: 12px;
  align-items: center;
}

.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 56px;
  overflow: hidden;
}

.title {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121212;
  letter-spacing: 0.34px;
}

.description {
  margin-top: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #7d7d7d;
}

.right {
  flex-shrink: 0;
  max-width: 280px;
  margin-left: 70px;
}

.button {
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 10px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 18px; /* 150% */
  color: #fff;
  text-align: center;
  letter-spacing: 0.14px;
  background: #1c70ee;
  border: 0;
  border-radius: 12px;
  outline: none;
  box-shadow: none;
}

.arrow {
  flex-shrink: 0;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  cursor: pointer;
}

.mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  background: #fff;
  border-radius: 8px;
}

.mobile_content {
  padding-right: 30px;
}

.mobile_description {
  font-size: 12px;
}

.mobile_icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  flex-shrink: 0;
  width: 91px;
  height: 84px;
}

.mobile_button {
  max-width: 220px;
  margin-top: 16px;
}

.profiled {
  margin-top: 8px;
}

.newMain {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 12px;
  background: linear-gradient(90deg, #e1edff 0%, #fff 58.53%);
  justify-content: space-between;

  .newMain_left {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    svg {
      flex: 0 0 20px;
    }
  }

  .newMain_right {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #1c70ee;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;

    svg {
      flex: 0 0 16px;
    }
  }
}

.pTag {
  display: flex;
  align-items: center;
  gap: 8px;
}
