@import 'styles/variable';
@import 'styles/mixins';

#hamburgerMenu {
  touch-action: none;

  // background: #136fd3;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: 0;
  }

  @include md {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
}

#hamburgerMenu input {
  position: absolute;
  top: -7px;
  left: -5px;

  /* hide this */
  z-index: 2;
  display: block;
  width: 17px;
  height: 32px;
  cursor: pointer;
  opacity: 0;

  /* and place it over the span */
}

#hamburgerMenu span {
  position: relative;
  z-index: 1;
  display: block;
  width: 12px;
  height: 2px;
  margin-bottom: 3px;
  background: #4e5969;
  border-radius: 3px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  transform-origin: 4px 0;

  &:first-child {
    transform-origin: 0% 0%;
  }

  &:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
}

.active {
  & span {
    opacity: 1;
    transform: rotate(-45deg) translate(2px, -1px);
  }

  & span:nth-last-child(3) {
    transform: rotate(45deg) translate(4px, -1px);
  }

  & span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
}

.profile {
  &Avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.vipAvatar {
  position: relative;
  box-sizing: border-box;
  width: 31px;
  height: 31px;
  border: 2px solid #ffc248;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

.hamburgerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &_getStarted {
    width: 116px;
    height: 35px;
    font-size: 14px;
    line-height: 35px;
    color: #2378e5;
    text-align: center;
    // border: 1px solid #579bf5;
    border-radius: 20px;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background: #2378e5;
    }

  }

  @media screen and (max-width: 570px) {
    &_getStarted {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 12px 16px;
      margin-right: 18px;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      background: #1C70EE;
      border-radius: 8px;


    }
  }


  .mobile {
    &_menu {
      &_off {
        display: flex;
        align-items: center;
        justify-content: center;

        //width: 27px;
        height: 55px;
        margin-left: 16px;
        transition: width 0.3ms ease;
      }

      &_open {
        //width: 18px;
      }
    }
  }
}