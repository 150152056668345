.textEditor {
  cursor: pointer;

  :hover > * {
    border-color: black;
  }

  // class name: quill
  & > div {

    // class name: ql-toolbar ql-snow
    & > div:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    // class name: ql-container ql-snow
    & > div:nth-child(2) {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 150px;
    }
  }
}