/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/selector-no-union-class-name */
/* stylelint-disable selector-max-class */
/* stylelint-disable order/properties-order */
@import "styles/variable";
@import "styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  position: fixed;
  z-index: 7;
  width: calc(100% - 24px);
  border-radius: 8px;
  background: #fff;
  padding: 12px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
  left: 12px;
  bottom: 20px;
  padding-left: 0;
  transform: translateY(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .closeImage {
      width: 30px;
      height: 30px;
      padding: 10px;
      position: relative;
      bottom: 4px;
      left: 4px;
    }

    .icon {
      width: 36px;
      height: 36px;
      background: #2378e5;
      overflow: hidden;
      border-radius: 6.33px;
      margin-left: 5px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .titleContainer {
      margin-left: 10px;

      .mainTitle {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .subTitle {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .right {
    color: #fff;
  }

  &_show {
    transform: translateY(0);
    opacity: 1;
  }

  &_hide {
    transform: translateY(100px);
    opacity: 0;
  }
}

.ModalAppRedirect {
  &Option {
    display: flex;

    &:first-child {
      margin-bottom: 15px;
    }

    img {
      padding: 5px;
      border: 0.2px solid #bcbcbc;
      border-radius: 4px;
      margin-top: 3px;
    }

    .BossjobLogoText {
      margin-top: 12px;
      margin-left: 10px;
      flex: auto;
    }

    &Action {
      float: right;

      button {
        min-width: 107px;
      }
    }
  }
}