.section {
  margin-top: 16px;
  margin-bottom: 0;
  margin-left: 20px;
  font-family: 'Product Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #353535;
  text-transform: capitalize;

  .item {
    margin-left: 30px;
    font-family: 'Product Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #353535;
  }
}

.lineCamp {
  span {
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.industryContainer {
  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    padding: 2px 4px;
    color: #fff;
    background-color: #2378e5;
    border-radius: 10px;
  }

  :global {
    & .MuiMenuItem-root {
      box-sizing: border-box;
      height: 38px;

      // display: none;
      min-height: 0;
      color: #515151;

      & .MuiTypography-root {
        font-size: 14px;
      }
    }
  }
}

.wrapper {
  :global {
    .MuiList-root.MuiList-padding {
      height: 100%;
    }
  }
}
