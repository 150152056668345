.recruiterWrapper {
  position: relative;
  min-height: 400px;
  padding: 0 0 24px;

  .container {
    max-height: 474px;
    padding: 0 32px;
    overflow-y: scroll;
  }

  .mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 120px;
    background-color: #ccc;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .header {
    position: relative;
    z-index: 10;
    padding: 0 32px;
    padding-top: 74px;
    margin-bottom: 20px;

    .avatar {
      width: 80px;
      height: 80px;
      border: 2px solid var(--black-00-ffffff, #FFF);
      border-radius: 50%;
    }
  }

  .fullName {
    padding-top: 6px;
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #121212;
  }

  .position {
    display: flex;
    align-items: center;
    padding-top: 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #121212;
    letter-spacing: 0.13px;
  }

  .hrTitle {
    margin-right: 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #7D7D7D;
    letter-spacing: 0.13px;
  }

  .sevenDay {
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border: 1px solid #F6F6F6;
    border-radius: 2px;
  }

  .obtained {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #515151;

    span {
      color: #2378E5;
    }
  }

  .medal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 20px;

  }

  .title {
    padding-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    color: #121212;

  }

  .company {
    .companyContent {
      display: flex;
      align-items: center;
      padding: 12px 0 24px;
      border-bottom: 1px solid #E4E4E4;

    }

    .companyLogo {
      flex-shrink: 0;
      width: 42px;
      height: 42px;
      margin-right: 12px;
      border-radius: 4px;
    }

    .companyName {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #121212;

      svg {
        position: relative;
        top: 2px;
      }
    }


  }

  .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: 32px;
    overflow: hidden;

    .skillItem {
      display: inline-block;
      align-items: center;
      height: 32px;
      padding: 0 12px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      color: #121212;
      white-space: nowrap;
      border: 1px solid #e8e8ea;
      border-radius: 4px;
    }

    .skillItemForOverflow {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .labelBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #7D7D7D;
    letter-spacing: 0.14px;

    span {
      display: flex;
      align-items: center;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      flex-shrink: 0;
      margin: 0 8px;
    }
  }

  .job {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-top: 12px;

    .item {
      width: calc(50% - 6px);
      padding: 8px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: #515151;
      background: #FFF;
      border: 1px solid #E4E4E4;
      border-radius: 8px;

    }

    .jobTitle {
      display: flex;
      align-items: center;
      padding-bottom: 2px;

      p {
        flex: 1;
        width: 0;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        flex-shrink: 0;
        margin-right: 4px;
      }
    }


  }

  .page {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}