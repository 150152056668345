@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.modal {
  &Content {
    position:relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    @include msd {
      height: 100%;
    }
  }

  &Header {
    display: flex;
    padding: 16px 24px;
    border-bottom: 1px solid rgba($lightgrey, 0.5);
    background-color: $white-1;
    border-radius: 5px;
    position:sticky;
    top:0;
    z-index:2;

    &Title {
      flex: auto;
    }
  }

  &Footer {
    border-top: 1px solid rgba($lightgrey, 0.5);
    padding: 16px 40px;
    display: flex;
    justify-content: flex-end;
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 1;
    
    button {
      &:last-child {
        margin-left: 10px;
        background-color: $primaryBlue;
        color: $white-3;
        
        &:hover {
          box-shadow: $box-shadow-1;
        }
      }

      @include msd{
        width:100%;
      }
    }
  }

  &CloseButton {
    margin-top: 3px;
    cursor: pointer;
    z-index: 9999;
  }
}
