@import 'styles/variable';

@keyframes fadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: .6;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    opacity: .6;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.mobileFullPageMenuContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.maskshow {
  position: fixed;
  inset: 0;
  z-index: 1000;
  visibility: visible;
  background-color: rgba(0, 0, 0.5);
  animation: fadeIn 200ms linear forwards;
}

.maskhide {
  position: fixed;
  inset: 0;
  z-index: 1000;
  visibility: hidden;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  animation: fadeOut 200ms linear forwards;
}

@keyframes slideIn {
  from {
    right: -100%;
    visibility: hidden;
  }

  to {
    right: 0;
    visibility: visible;
  }
}

@keyframes slideOut {
  from {
    right: 0;
    visibility: visible;
  }

  to {
    right: -100%;
    visibility: hidden;
  }
}

.mobileFullPageMenu {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70vw;
  min-width: 180px;
  visibility: hidden;
  background-color: #F8F9FA;
}

.mobileFullPageMenu.open {
  animation: slideIn 200ms linear forwards
}

.mobileFullPageMenu.close {
  animation: slideOut 200ms linear forwards;
}


.closeIcon {
  margin-right: 24px;

  span {
    position: relative;
    z-index: 1;
    display: block;
    width: 12px;
    height: 2px;
    margin-bottom: 3px;
    background: #000;
    border-radius: 3px;
    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1) 0.3s,
      background 0.3s cubic-bezier(0.77, 0.2, 0.05, 1) 0.3s,
      opacity 0.55s ease 0.3s;
    transform-origin: 4px 0;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }


}

.active {
  span {
    opacity: 1;
    transform: rotate(-45deg) translate(2px, -1px);
  }

  span:nth-last-child(3) {
    transform: rotate(45deg) translate(4px, -1px);
  }

  span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
}

.menuListWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  // padding-right: 5px;
  background-color: rgb(248, 249, 250);

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
    border-left: 3px solid transparent;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e4e4e4;
  }

  p {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 14px 0;
    margin: 0;

  }

  span {
    font-size: 16px
  }
}

.menuList {
  display: block;
  padding: 0 0 10px;

  // &:hover {
  //   // color: $primaryBlue;
  //   // font-weight: bold;
  // }


}

.downloadApp {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  background: #000;
  border-radius: 12px;

  span {
    margin-right: 8px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}

.defaultLink {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  margin-bottom: 8px;
  font-size: 16px !important;
  line-height: 24px;
  color: #111212 !important;

  &:first-child {
    margin-bottom: 0;
    border-top: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $primaryBlue;
    cursor: pointer;
  }
}

.divider {
  border-top: 3px solid #f0f0f0;
}

.activeLink {
  width: auto;
  font-weight: bold;
  color: $primaryBlue !important;
}

.hotTag {
  top: 2px;
  right: -49px;
  display: flex;
  padding: 1px 5px;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: white;
  background: #d2030f;
  border-radius: 8px;
}

.unCompleted::after {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-bottom: 1px;
  margin-left: 4px;
  overflow: hidden;
  content: ' ';
  background-color: #f44336;
  border-radius: 50%;
}

.findJobMenu {
  padding: 0;
}